import { merge } from 'lodash';

const defaultArgs = {
  first: 1000,
  where: {
    totalLiquidity_gt: 1e-6, // To exclude dust liquidity
  },
};

const defaultAttrs = {
  totalLiquidity: true,
};

export default (args = {}, attrs = {}) => ({
  pools: {
    __args: merge({}, defaultArgs, args),
    ...merge({}, defaultAttrs, attrs),
  },
});
