import { PoolLiquidity } from '@/services/pool/types';
import { QueryBuilder } from '@/types/subgraph';

import Service from '../../balancer-subgraph.service';
import poolQueryBuilder from './query';

export default class PoolLiquidities {
  service: Service;
  query: QueryBuilder;

  constructor(service: Service, query: QueryBuilder = poolQueryBuilder) {
    this.service = service;
    this.query = query;
  }

  public async get(): Promise<PoolLiquidity[]> {
    try {
      const query = this.query();
      const data = await this.service.client.get(query);
      return data.pools;
    } catch (error: unknown) {
      console.error('Unexpected error in PoolLiquidities query: ', error);
      return [];
    }
  }
}
